import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Once upon a time, the payments industry was easy to understand – plastic credit cards. Today, your mobile phone, watch, TV and even your car can pay for things. Consumers are paying with social media, digital currency, and connected devices. Do you understand today’s payments environment? You need to connect with, touch, and explore the payments technology that consumers and merchants will be using tomorrow. The only place to see it all is at TRANSACT 15. From Intel’s “Internet of Things” payments technology to Visa’s payments-connected car, today’s payments ecosystem is displayed on the largest show floor in payments industry history at TRANSACT 15.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.greensheet.com/newswire.php?flag=display_story&id=38183"
      }}>{`Read the Story at greensheet.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      